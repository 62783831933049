import { useCallback, useEffect, useState } from "react";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useDebouncedCallback } from "use-debounce";

export interface useResetPasswordPopupReturnType {
  closePasswordResetPopup: () => void;
  closePasswordChangePopup: () => void;
  isPasswordReset: boolean;
  isPasswordChanged: boolean;
  isOtherPopupEnabled: boolean;
}

export const useChangeResetPasswordPopup = (): useResetPasswordPopupReturnType => {
  const router = useRouter();
  const pathname = usePathname();
  const query = useSearchParams();

  const [isPasswordReset, setIsPasswordReset] = useState<boolean>(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState<boolean>(false);
  const [isOtherPopupEnabled, setIsOtherPopupEnabled] = useState<boolean>(true);

  useEffect(() => {
    const resetPasswordPopup = query.get("resetPasswordPopup");
    const changePasswordPopup = query.get("changePasswordPopup");

    const routeWithoutQueryParameters = pathname;
    if (resetPasswordPopup) {
      setIsPasswordReset(true);
      setIsOtherPopupEnabled(false);
      router?.replace(routeWithoutQueryParameters, undefined);
    }
    if (changePasswordPopup) {
      setIsPasswordChanged(true);
      router?.replace(routeWithoutQueryParameters, undefined);
    }
  }, [pathname, query, router]);

  const enableMergingPopup = useDebouncedCallback(() => {
    setIsOtherPopupEnabled(true);
  }, 1000);

  const closePasswordResetPopup = useCallback(() => {
    setIsPasswordReset(false);
    enableMergingPopup();
  }, [enableMergingPopup]);

  const closePasswordChangePopup = useCallback(() => {
    setIsPasswordChanged(false);
  }, []);

  return {
    isPasswordReset,
    isPasswordChanged,
    closePasswordResetPopup,
    closePasswordChangePopup,
    isOtherPopupEnabled,
  };
};
