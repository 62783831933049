import { LocaleFormat } from "./types";

export const formatLocaleString = (
  locale: string,
  fromFormat: LocaleFormat,
  toFormat: LocaleFormat,
) => {
  if (fromFormat === LocaleFormat.xx_dash_xx && toFormat === LocaleFormat.xx_XX) {
    // Regular expression to match the pattern 'xx-xx'
    const regex = /^[a-z]{2}-[a-z]{2}$/;

    // Check if the string matches the pattern
    if (regex.test(locale)) {
      // Split the string at the hyphen
      const parts = locale.split("-");

      // Capitalize the second part and recombine with the first part using an underscore
      return `${parts[0]}_${parts[1].toUpperCase()}`;
    } else {
      throw new Error(
        `Wrong string format, ${fromFormat} format expected, instead received string: ${locale}`,
      );
    }
  }
};
