export const SLIDE_STEP = 3;
export const RECENTLY_VIEWED_SLIDE_STEP = 6;

export const CATEGORIES_CAROUSEL_PREFIX = "CategoriesCarousel";

export const CATEGORIES_CAROUSEL_CLASSES = {
  swiper: `${CATEGORIES_CAROUSEL_PREFIX}-swiper`,
  element: `${CATEGORIES_CAROUSEL_PREFIX}-element`,
  slider: `${CATEGORIES_CAROUSEL_PREFIX}-slider`,
  container: `${CATEGORIES_CAROUSEL_PREFIX}-container`,
  elements: `${CATEGORIES_CAROUSEL_PREFIX}-elements`,
  buttons: `${CATEGORIES_CAROUSEL_PREFIX}-buttons`,
  button: `${CATEGORIES_CAROUSEL_PREFIX}-button`,
  buttonLeft: `${CATEGORIES_CAROUSEL_PREFIX}-buttonLeft`,
};

export const COMPONENT_ID = "categories-carousel";

export enum CarouselUseType {
  Default = "default",
  RecentlyViewed = "recentlyViewed",
  CrossSell = "crossSell",
}

export interface CarouselConfig {
  slideStep: number;
  mobileSlidesPerView: number;
  tabletSlidesPerView: number;
  desktopSlidesPerView: number;
}

export const carouselConfigs: Record<CarouselUseType, CarouselConfig> = {
  default: {
    slideStep: SLIDE_STEP,
    mobileSlidesPerView: 3,
    tabletSlidesPerView: 5,
    desktopSlidesPerView: 6,
  },
  crossSell: {
    slideStep: SLIDE_STEP,
    mobileSlidesPerView: 2.65,
    tabletSlidesPerView: 3.8,
    desktopSlidesPerView: 6,
  },
  recentlyViewed: {
    slideStep: RECENTLY_VIEWED_SLIDE_STEP,
    mobileSlidesPerView: 2.65,
    tabletSlidesPerView: 4,
    desktopSlidesPerView: 6.3,
  },
};
