"use client";

import React from "react";

import { VariablesContext, VariablesContextValue } from "./context";

export function VariablesProvider({
  value,
  children,
}: {
  value: VariablesContextValue;
  children: React.ReactNode;
}) {
  return (
    <VariablesContext.Provider value={value}>{children}</VariablesContext.Provider>
  );
}
