"use client";
import React, { isValidElement, ReactNode, useEffect, useState } from "react";

import { ClassNameMap, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import clsx from "clsx";
import "swiper/css/bundle";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Swiper, SwiperSlide } from "~/lib/swiper/component";

import { useSwiper } from "../../hooks/use-swiper";
import { NavigationButtons } from "../navigation-buttons/component";

import {
  carouselConfigs,
  CarouselUseType,
  CATEGORIES_CAROUSEL_CLASSES,
} from "./constants";
import { CategoriesCarouselContainer } from "./styled";

export interface CategoriesCarouselProps {
  classes?: Partial<ClassNameMap>;
  isLeftSideCarousel?: boolean;
  isScrollButtonShown?: boolean;
  isOnFocusActionEnabled?: boolean;
  testAutomationId?: string;
  id?: string;
  carouselType?: CarouselUseType;
  onUserAction?: (index?: number) => void;
  onInViewItemsChange?: (items: number[]) => void;
}

const getKeyForSlide = (node: ReactNode, index: number) => {
  return isValidElement(node) ? node.props.meta?.deliveryId || index : index;
};

const COMPONENT_ID = "categories-carousel";

export const CategoriesCarousel: React.FC<
  React.PropsWithChildren<CategoriesCarouselProps>
> = ({
  children,
  classes: externalClasses,
  isLeftSideCarousel = false,
  carouselType = CarouselUseType.Default,
  testAutomationId = COMPONENT_ID,
  isScrollButtonShown = true,
  isOnFocusActionEnabled = true,
  id = COMPONENT_ID,
  onUserAction,
  onInViewItemsChange,
}) => {
  const theme = useTheme();
  const config = carouselConfigs[carouselType];

  const {
    setSwiper,
    isNextEnabled,
    isPrevEnabled,
    windowWidth,
    handleSliceChange,
    handleNextClick,
    handlePrevClick,
    handleElementFocus,
  } = useSwiper(config.slideStep);

  const isTablet = useMediaQuery(theme.breakpoints.up("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const [activeIndex, setActiveIndex] = useState(0);

  const handleInViewItemChanged = (activeIndex: number) => {
    const getSlidesPerView = () => {
      if (isDesktop) {
        return config.desktopSlidesPerView;
      }
      if (isTablet) {
        return config.tabletSlidesPerView;
      }
      return config.mobileSlidesPerView;
    };

    const viewWindow = getSlidesPerView();

    onInViewItemsChange &&
      onInViewItemsChange(
        Array.from<number, number>(
          { length: viewWindow },
          (_, i) => activeIndex + i,
        ),
      );
  };

  useEffect(() => {
    handleInViewItemChanged(activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleInViewItemChanged(activeIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  return (
    <CategoriesCarouselContainer
      {...getTestAutomationProps(testAutomationId)}
      className={externalClasses?.root}
      id={id}
    >
      <Box
        className={clsx(CATEGORIES_CAROUSEL_CLASSES.swiper, externalClasses?.swiper)}
      >
        <Box
          className={clsx(
            CATEGORIES_CAROUSEL_CLASSES.container,
            externalClasses?.container,
          )}
        >
          <Box
            className={clsx(
              CATEGORIES_CAROUSEL_CLASSES.elements,
              externalClasses?.elements,
            )}
            {...getTestAutomationProps("elements")}
          >
            <Swiper
              key={windowWidth}
              initialSlide={isLeftSideCarousel ? 0 : React.Children.count(children)}
              spaceBetween={0}
              onSwiper={setSwiper}
              onSlideChange={(swiper) => {
                setActiveIndex(swiper.activeIndex);
                handleSliceChange(swiper);
              }}
              slidesPerView={config.mobileSlidesPerView}
              {...(onUserAction && {
                onSliderFirstMove: () => onUserAction(),
              })}
              breakpoints={{
                [theme.breakpoints.values.sm]: {
                  slidesPerView: config.tabletSlidesPerView,
                },
                [theme.breakpoints.values.md]: {
                  slidesPerView: config.desktopSlidesPerView,
                },
              }}
            >
              {!isLeftSideCarousel && (
                <SwiperSlide
                  className={clsx(
                    CATEGORIES_CAROUSEL_CLASSES.slider,
                    externalClasses?.slider,
                  )}
                  {...getTestAutomationProps("element")}
                />
              )}
              {React.Children.map(children, (node, index) => (
                <SwiperSlide
                  key={getKeyForSlide(node, index)}
                  className={clsx(
                    CATEGORIES_CAROUSEL_CLASSES.slider,
                    externalClasses?.slider,
                  )}
                  {...(onUserAction && {
                    onClick: () => onUserAction(index),
                  })}
                  {...(isOnFocusActionEnabled && {
                    onFocus: () => handleElementFocus(index),
                  })}
                  {...getTestAutomationProps("element")}
                >
                  <Box
                    className={clsx(
                      CATEGORIES_CAROUSEL_CLASSES.element,
                      externalClasses?.element,
                    )}
                  >
                    {node}
                  </Box>
                </SwiperSlide>
              ))}
              {isLeftSideCarousel && (
                <SwiperSlide
                  className={clsx(
                    CATEGORIES_CAROUSEL_CLASSES.slider,
                    externalClasses?.slider,
                  )}
                  {...getTestAutomationProps("element")}
                />
              )}
            </Swiper>
          </Box>
        </Box>
        {isScrollButtonShown ? (
          <NavigationButtons
            classes={{
              root: clsx(
                CATEGORIES_CAROUSEL_CLASSES.buttons,
                externalClasses?.buttons,
              ),
              button: clsx(
                CATEGORIES_CAROUSEL_CLASSES.button,
                externalClasses?.button,
              ),
              buttonLeft: clsx(
                CATEGORIES_CAROUSEL_CLASSES.buttonLeft,
                externalClasses?.buttonLeft,
              ),
              buttonRight: externalClasses?.buttonRight,
            }}
            leftButtonProps={{
              disabled: !isPrevEnabled,
              onClick: () => {
                onUserAction && onUserAction();
                handlePrevClick();
              },
            }}
            rightButtonProps={{
              disabled: !isNextEnabled,
              onClick: () => {
                onUserAction && onUserAction();
                handleNextClick();
              },
            }}
          />
        ) : null}
      </Box>
    </CategoriesCarouselContainer>
  );
};
