"use client";

import React, { FC } from "react";

import { Box, Typography } from "@mui/material";

import { CombinedButton } from "~/components/combined-button/component";
import { Image } from "~/components/image/component";
import { prepareTextAttribute } from "~/helpers/prepare-text-attribute";
import { getImageSrc } from "~/utils/image/get-image-src";

import { HERO_BANNER_CATEGORY_CLASSES, imageTemplates } from "./constants";
import { HeroBannerCategoryContainer } from "./styled";
import { HeroBannerCategoryProps } from "./types";
export const HeroBannerCategory: FC<HeroBannerCategoryProps> = ({
  bannerSettings,
  bannerImageSettings: image,
  callToAction,
  children,
}) => {
  return (
    <HeroBannerCategoryContainer
      textColor={bannerSettings?.textColor ?? ""}
      id="hero-banner-category-carousel"
    >
      <Box className={HERO_BANNER_CATEGORY_CLASSES.banner}>
        <Image
          className={HERO_BANNER_CATEGORY_CLASSES.bannerBackgroundImage}
          imageTemplates={imageTemplates}
          src={getImageSrc(image?.source ?? undefined)}
          alt={prepareTextAttribute(image?.alt, true)}
          title={prepareTextAttribute(image?.title)}
          aria-hidden={true}
          fetchPriority="high"
        />
        <Typography
          className={HERO_BANNER_CATEGORY_CLASSES.bannerTitle}
          variant="h1Regular"
        >
          {bannerSettings?.headerText}
        </Typography>
        <Box className={HERO_BANNER_CATEGORY_CLASSES.bannerButtonsContainer}>
          {callToAction?.map((link) => {
            if (link) {
              return (
                <CombinedButton
                  className={HERO_BANNER_CATEGORY_CLASSES.bannerButton}
                  key={`${link.label}-${link.url}`}
                  variant="outlined"
                  href={link.url ?? ""}
                >
                  {link.label}
                </CombinedButton>
              );
            }
            return null;
          })}
        </Box>
      </Box>
      {children as React.ReactNode}
    </HeroBannerCategoryContainer>
  );
};
