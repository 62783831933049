import { ImageTemplates } from "~/components/image/types";
import { WindowFormat } from "~/context/breakpoints/types";

export const imageTemplates: ImageTemplates = {
  [WindowFormat.mobile]: "banner-mobile",
  [WindowFormat.tablet]: "banner-tablet",
  [WindowFormat.desktop]: "banner-regulardesktop",
  [WindowFormat.wideDesktop]: "banner-largedesktop",
};

export const HERO_BANNER_CATEGORY_PREFIX = "HeroBannerCategory";

export const HERO_BANNER_CATEGORY_CLASSES = {
  banner: `${HERO_BANNER_CATEGORY_PREFIX}-banner`,
  bannerTitle: `${HERO_BANNER_CATEGORY_PREFIX}-bannerTitle`,
  bannerBackgroundImage: `${HERO_BANNER_CATEGORY_PREFIX}-bannerBackgroundImage`,
  bannerButtonsContainer: `${HERO_BANNER_CATEGORY_PREFIX}-bannerButtonsContainer`,
  bannerButton: `${HERO_BANNER_CATEGORY_PREFIX}-bannerButton`,
};
