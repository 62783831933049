"use client";
import React from "react";

import { HeroBannerCarouselProps } from "~/bff/components/HeroBannerCarousel";
import { CategoriesCarousel } from "~/components/carousel/components/categories-carousel/component";

export const HeroBannerCarousel: React.FC<HeroBannerCarouselProps> = ({
  children,
}) => {
  return (
    <CategoriesCarousel isLeftSideCarousel={true}>
      {children as React.ReactNode}
    </CategoriesCarousel>
  );
};
