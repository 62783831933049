import {
  DataLayerData,
  DataLayerDataTypes,
  NewDataLayerDataTypes,
  dataLayerDataWithUndefinedValues,
  newDataLayerDataWithUndefinedValues,
  shoppingBagDataLayerWithUndefinedValues,
  ShoppingBagDataLayerDataTypes,
} from "~/constants/data-layer";

type ClearExtraFieldsReturn = DataLayerDataTypes &
  NewDataLayerDataTypes &
  ShoppingBagDataLayerDataTypes;

const clearExtraFields = (
  data: DataLayerDataTypes | NewDataLayerDataTypes | ShoppingBagDataLayerDataTypes,
): DataLayerDataTypes & NewDataLayerDataTypes & ShoppingBagDataLayerDataTypes => {
  const unpreparedResult = {
    ...dataLayerDataWithUndefinedValues,
    ...newDataLayerDataWithUndefinedValues,
    ...shoppingBagDataLayerWithUndefinedValues,
    ...data,
  };

  const preparedResult = {
    ...unpreparedResult,
    [DataLayerData.siteCountry]: unpreparedResult[
      DataLayerData.siteCountry
    ] as ClearExtraFieldsReturn[DataLayerData.siteCountry],
    [DataLayerData.storeSelected]:
      unpreparedResult[DataLayerData.storeSelected] ?? undefined,
  };

  return preparedResult;
};

export function pushDataLayer(
  data: DataLayerDataTypes | NewDataLayerDataTypes | ShoppingBagDataLayerDataTypes,
): void {
  const cleanedData = clearExtraFields(data);
  // eslint-disable-next-line no-restricted-globals
  window?.dataLayer?.push({ [DataLayerData.ecommerce]: null });
  // eslint-disable-next-line no-restricted-globals
  window?.dataLayer?.push(cleanedData);
}
