"use client";
import React, { ReactElement } from "react";

import { ClassNameMap } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { IconButtonProps } from "@mui/material/IconButton/IconButton.d";
import clsx from "clsx";

import { getTestAutomationProps } from "~/helpers/test-automation-props";
import { Left32 } from "~/theme/icons/components/left";
import { Right32 } from "~/theme/icons/components/right";

interface NavigationButtonsProps {
  classes?: Partial<ClassNameMap>;
  leftButtonIcon?: ReactElement;
  rightButtonIcon?: ReactElement;
  leftButtonProps?: IconButtonProps;
  rightButtonProps?: IconButtonProps;
}

export const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  classes: externalClasses,
  leftButtonIcon = <Left32 />,
  rightButtonIcon = <Right32 />,
  leftButtonProps,
  rightButtonProps,
}) => {
  return (
    <Box
      sx={{
        display: "inline-block",
        whiteSpace: "nowrap",
      }}
      className={externalClasses?.root}
      {...getTestAutomationProps("navigation-buttons")}
    >
      <IconButton
        aria-label="Previous"
        className={clsx(externalClasses?.button, externalClasses?.buttonLeft)}
        {...leftButtonProps}
        {...getTestAutomationProps("button-left")}
        size="large"
      >
        {leftButtonIcon}
      </IconButton>
      <IconButton
        aria-label="Next"
        className={clsx(externalClasses?.button, externalClasses?.buttonRight)}
        sx={{
          marginLeft: (theme) => theme.spacing(1),
        }}
        {...rightButtonProps}
        {...getTestAutomationProps("button-right")}
        size="large"
      >
        {rightButtonIcon}
      </IconButton>
    </Box>
  );
};
