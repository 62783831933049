"use client";

import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from "react";

import { DEFAULT_LOCALE, LOCALES } from "~/constants/i18n";
import { formatLocaleString } from "~/helpers/locales/format-locale-string";
import { LocaleFormat } from "~/helpers/locales/types";
import { setDYContext } from "~/helpers/set-dy-context";
import { AzureConfigurator } from "~/services/azure-configurator/azure-configurator";
import { chooseCampaignVariations } from "~/utils/dynamic-yield/choose-campaign-variations";
import {
  DYChooseResponseData,
  ChooseCampaignVariationsArgs,
  DYContextType,
  DyCampaignName,
} from "~/utils/dynamic-yield/types";

import { DynamicYieldContext } from "./context";
import { DynamicYieldContextValue } from "./types";

export type DynamicYieldProviderProps = PropsWithChildren<{
  pageLocale: LOCALES;
  pageData?: string[];
  pageType: DYContextType;
  pageLocation: string;
  previewIds?: string | string[];
  campaignNames: DyCampaignName[];
}>;

export const DynamicYieldProvider: FC<DynamicYieldProviderProps> = ({
  children,
  pageLocale,
  pageData,
  pageType,
  pageLocation,
  previewIds,
  campaignNames,
}) => {
  const [dyChooseResponseData, setDyChooseResponseData] =
    useState<DYChooseResponseData | null>(null);

  const isDynamicYieldEnabled =
    AzureConfigurator.getConfig(pageLocale)?.featureFlagList?.dynamicYield?.enabled;

  const dyPageLocale = formatLocaleString(
    pageLocale,
    LocaleFormat.xx_dash_xx,
    LocaleFormat.xx_XX,
  );

  const chooseCampaignVariationsArgs = useMemo<ChooseCampaignVariationsArgs>(
    () => ({
      pageType,
      pageData,
      pageLocation,
      pageLocale: dyPageLocale ?? DEFAULT_LOCALE,
      campaignNames,
      previewIds: previewIds ?? [],
    }),
    [pageType, pageData, pageLocation, dyPageLocale, campaignNames, previewIds],
  );

  const dyContextInfo = {
    locale: dyPageLocale ?? DEFAULT_LOCALE,
    data: pageData,
    type: pageType,
  };

  useEffect(() => {
    setDYContext(
      dyContextInfo.type,
      dyContextInfo.locale,
      dyContextInfo.data ?? null,
    );

    async function performChoose() {
      const dyChooseResponseDataLocal = await chooseCampaignVariations(
        chooseCampaignVariationsArgs,
      );

      setDyChooseResponseData(dyChooseResponseDataLocal);
    }

    if (
      isDynamicYieldEnabled &&
      chooseCampaignVariationsArgs.campaignNames.length > 0
    ) {
      performChoose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contextValue: DynamicYieldContextValue = useMemo(
    () => ({
      chooseCampaignVariationsArgs,
      chooseResponseData: dyChooseResponseData,
    }),
    [chooseCampaignVariationsArgs, dyChooseResponseData],
  );

  return (
    <DynamicYieldContext.Provider value={contextValue}>
      {children}
    </DynamicYieldContext.Provider>
  );
};
