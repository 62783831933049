import { DYContextType } from "~/utils/dynamic-yield/types";
import { Logger } from "~/utils/logger";

/* eslint-disable no-restricted-globals */
export const setDYContext = (
  type: DYContextType,
  lng: string | undefined,
  data: (string | null | undefined)[] | null,
) => {
  if (typeof window !== "undefined") {
    window.DY = window.DY ?? {};

    const newRecommendationContext =
      Array.isArray(data) && data.length > 0 ? { type, lng, data } : { type, lng };

    const isPrevContextEqualsNew =
      JSON.stringify(window.DY.recommendationContext) ===
      JSON.stringify(newRecommendationContext);

    if (isPrevContextEqualsNew) {
      return;
    }

    window.DY.recommendationContext = newRecommendationContext;
    Logger.getLogger("DY:setDYContext").info(
      `DY context has been set to: ${JSON.stringify(
        window.DY.recommendationContext,
        null,
        2,
      )}`,
    );
  }
};
/* eslint-enable no-restricted-globals */
