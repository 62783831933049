import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import { getFormatMedia } from "~/theme/utils";

import { CATEGORIES_CAROUSEL_CLASSES } from "./constants";

export const CategoriesCarouselContainer = styled(Box)(({ theme }) => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  return {
    marginTop: theme.spacing(-4),
    color: "black",
    [`& .${CATEGORIES_CAROUSEL_CLASSES.swiper}`]: {
      display: "block",
      alignItems: "center",
      [BREAKPOINT_DESKTOP]: {
        display: "flex",
      },
    },
    [`& .${CATEGORIES_CAROUSEL_CLASSES.element}`]: {
      padding: theme.spacing(0.25),
      marginLeft: theme.spacing(-0.25),
      backgroundColor: "white",
    },
    [`& .${CATEGORIES_CAROUSEL_CLASSES.slider}`]: {
      width: "calc(100% / 3)",
      [BREAKPOINT_TABLET]: {
        width: "calc(100% / 5)",
      },
      [BREAKPOINT_DESKTOP]: {
        width: "calc(100% / 8)",
      },
    },
    [`& .${CATEGORIES_CAROUSEL_CLASSES.container}`]: {
      overflow: "hidden",
      flexGrow: 1,
    },
    [`& .${CATEGORIES_CAROUSEL_CLASSES.elements}`]: {
      width: "calc(6 / 5 * 100%)",
      [BREAKPOINT_TABLET]: {
        width: "calc(10 / 9 * 100%)",
      },
      [BREAKPOINT_DESKTOP]: {
        width: "calc(16 / 15 * 100%)",
      },
    },
    [`& .${CATEGORIES_CAROUSEL_CLASSES.buttons}`]: {
      width: theme.spacing(2),
      minWidth: theme.spacing(2),
      [BREAKPOINT_TABLET]: {
        width: theme.spacing(4),
        minWidth: theme.spacing(4),
      },
      [BREAKPOINT_DESKTOP]: {
        width: theme.spacing(23),
        minWidth: theme.spacing(23),
      },
    },
    [`& .${CATEGORIES_CAROUSEL_CLASSES.button}`]: {
      display: "none",
      [BREAKPOINT_DESKTOP]: {
        display: "inline-flex",
      },
    },
    [`& .${CATEGORIES_CAROUSEL_CLASSES.buttonLeft}`]: {
      [BREAKPOINT_DESKTOP]: {
        marginLeft: theme.spacing(3),
      },
    },
  };
});
