import React from "react";

import { Box, BoxProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import { COLOR_BLACK, COLOR_PRIMARY_WHITE, COLOR_WHITE } from "~/theme/colors";
import { getFormatMedia } from "~/theme/utils";

import { HERO_BANNER_CATEGORY_CLASSES } from "./constants";

interface StyleProps {
  textColor: string;
}

export const HeroBannerCategoryContainer = styled(
  ({ textColor, ...restProps }: StyleProps & BoxProps) => <Box {...restProps} />,
)(({ theme, textColor }) => {
  const { BREAKPOINT_TABLET, BREAKPOINT_DESKTOP } = getFormatMedia(theme);
  return {
    marginBottom: theme.spacing(4),
    [`& .${HERO_BANNER_CATEGORY_CLASSES.banner}`]: {
      position: "relative",
      zIndex: 0,
      padding: theme.spacing(2.5),
      marginBottom: theme.spacing(4),
      [BREAKPOINT_TABLET]: {
        padding: theme.spacing(8, 7.5),
      },
      [BREAKPOINT_DESKTOP]: {
        padding: theme.spacing(13.5, 15),
      },
    },
    [`& .${HERO_BANNER_CATEGORY_CLASSES.bannerTitle}`]: {
      textTransform: "inherit",
      color: textColor === "light" ? COLOR_WHITE : COLOR_BLACK,
      marginBottom: theme.spacing(2),
      [BREAKPOINT_TABLET]: {
        marginBottom: theme.spacing(3),
      },
      [BREAKPOINT_DESKTOP]: {
        textTransform: "inherit",
        marginBottom: theme.spacing(4),
      },
    },
    [`& .${HERO_BANNER_CATEGORY_CLASSES.bannerBackgroundImage}`]: {
      position: "absolute",
      width: "100%",
      height: "100%",
      inset: 0,
      zIndex: -1,
      objectFit: "cover",
    },
    [`& .${HERO_BANNER_CATEGORY_CLASSES.bannerButtonsContainer}`]: {
      display: "flex",
      flexWrap: "wrap",
      gap: theme.spacing(2),
      justifyContent: "center",
      [BREAKPOINT_TABLET]: {
        justifyContent: "flex-start",
      },
    },
    [`& .${HERO_BANNER_CATEGORY_CLASSES.bannerButton}`]: {
      background: COLOR_PRIMARY_WHITE,
      width: `calc((100% - ${theme.spacing(2)}) / 2)`,
      [BREAKPOINT_TABLET]: {
        width: "auto",
      },
      "[data-disabled='true']": {
        "&:hover, &:active": {
          background: COLOR_PRIMARY_WHITE,
        },
      },
    },
  };
});
